<template>
    <div
        v-if="locale === 'se'"
        :class="'plateContainerSweden ' + backgroundPlate"
    >
        <template v-if="redacted">
            <span class="unknownPart">{{ $t('redacted') }}</span>
        </template>
        <template v-else-if="registrationNumber === 'sensitivedata'">
            <span class="unknownPart">{{ $t('sensitivedata') }}</span>
        </template>
        <template v-else-if="unknownType">
            <span class="unknownPart">{{ registrationNumber }}</span>
        </template>
        <template v-else-if="plateIsStandardSwedishPlate(registrationNumber)">
            <span class="swedenPartOne">{{ plateTextSwedenPart1 }}</span>
            <span class="swedenPartTwo">{{ plateTextSwedenPart2 }}</span>
        </template>
        <template v-else>
            <span class="unknownPart">{{ registrationNumber }}</span>
        </template>
    </div>
    <div
        v-else
        :class="'plateContainer ' + backgroundPlate"
    >
        <template v-if="redacted">
            <span class="unknownPart">{{ $t('redacted') }}</span>
        </template>
        <template v-else-if="registrationNumber === 'sensitivedata'">
            <span class="unknownPart">{{ $t('sensitivedata') }}</span>
        </template>
        <template v-else-if="unknownType">
            <span class="unknownPart">{{ registrationNumber }}</span>
        </template>
        <template v-else>
            <span class="textPart">{{ plateText }}</span>
            <span class="numberPart1">{{ plateNumberPart1 }}</span>
            <span class="numberPart2">{{ plateNumberPart2 }}</span>
        </template>
    </div>
</template>

<script>
export default {
    name: 'DashboardDashboard',

    components: {
    },
    props: {
        registrationNumber: {
            type: String,
            required: true
        },
        registrationType: {
            type: Number,
            default: -1
        },
        redacted: {
            type: Boolean,
            default: false
        },
        useNoPlate: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            locale: process.env.VUE_APP_I18N_LOCALE
        }
    },
    computed: {
        plateTextSwedenPart1() {
            let letters = this.registrationNumber.substring(0, 3)

            return letters
        },
        plateTextSwedenPart2() {
            let letters = this.registrationNumber.substring(3, 6)

            return letters
        },
        plateText() {
            let letters = this.registrationNumber.substring(0, 2)

            return letters
        },
        plateNumberPart1() {
            let firstNumber = this.registrationNumber.substring(2, 4)
            return firstNumber
        },
        plateNumberPart2() {
            let secondNumber = this.registrationNumber.substring(4, 7)
            return secondNumber
        },
        backgroundPlate() {
            if (this.locale === 'se') {
                if (this.registrationType === null && this.useNoPlate === true) {
                    return 'noPlate'
                } else if (this.unknownType) {
                    return 'unknownplate'
                }
                return 'se'
            } else if (this.registrationType === 1) {
                return 'whiteplate'
            } else if (this.registrationType === 2) {
                return 'yellowplate'
            } else if (this.registrationType === 3) {
                return 'parrotplate'
            } else if (this.registrationType === null && this.useNoPlate === true) {
                return 'noPlate'
            } else {
                return 'unknownplate'
            }
        },
        unknownType() {
            if (this.registrationType === 1 || this.registrationType === 2 || this.registrationType === 3) {
                return false
            }

            return true
        }
    },
    updated() {
    },
    methods: {
        plateIsStandardSwedishPlate(plate) {
            if (plate.length > 6 || plate.length < 6) return false
            let regex = /([A-Za-z]{3})(\d{2,3})/
            return regex.test(plate)
        }
    }
}
</script>
